import React from 'react'

import Layout from '../components/layout'
import Placeholder from '../components/placeholder'
import Seo from '../components/seo'
import { injectIntl } from 'gatsby-plugin-intl'

const Contact = ({ intl }) => (
  <Layout>
    <Seo title={intl.formatMessage({ id: 'contact' })} />
    <Placeholder />
  </Layout>
)

export default injectIntl(Contact)
